import { extendTheme } from "@chakra-ui/react"
const theme = extendTheme({
  fonts: {
    heading: "definition, sans-serif",
    body: "definition, sans-serif",
  },
  sizes: {
    container: {
      xxl: "1600px",
      xl: "1440px",
    },
  },
  breakpoints: {
    xxl: "1600px",
    xl: "1440px",
  },
  fontSizes: {
    xxs: "0.5rem",
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1.0625rem",
    lg: "1.3125rem",
    xl: "1.5rem",
    "2xl": "1.75rem",
    "3xl": "2.25rem",
    "4xl": "2.625rem",
    "5xl": "3.5rem",
    "6xl": "4rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
  lineHeights: {
    undercut: "0.8",
    normal: "normal",
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: "2",
    3: ".75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
  },
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: "bold",
        lineHeight: "undercut",
        letterSpacing: "tight",
      },
    },
    Text: {
      baseStyle: {
        fontWeight: "normal",
        lineHeight: "shorter",
        letterSpacing: "tight",
      },
    },
    Button: {
      baseStyle: {
        borderRadius: "40px",
        lineHeight: "0",
        fontSize: { base: "xs", md: "sm" },
      },
      variants: {
        solid: {
          paddingRight: { base: 8, md: 12, lg: 16 },
          paddingLeft: { base: 8, md: 12, lg: 16 },
          paddingTop: { base: 2, md: 4, lg: 6 },
          paddingBottom: { base: 2, md: 4, lg: 6 },
          borderRadius: "12rem",
          fontSize: { base: "xs", md: "sm" },
          display: "block",
          fontWeight: "bold",
          letterSpacing: "normal",
          transition: "all .25s ease-in-out",
          _hover: {
            color: "brand.gray.ulight",
            background: "brand.violet.primary",
            transition: "all .225s ease-in-out",
          },
        },
        ghost: {
          paddingRight: { base: 8, md: 12, lg: 16 },
          paddingLeft: { base: 8, md: 12, lg: 16 },
          paddingTop: { base: 2, md: 4, lg: 6 },
          paddingBottom: { base: 2, md: 4, lg: 6 },
          border: "2px solid",
          display: "block",
          fontWeight: "bold",
          fontSize: { base: "xs", md: "sm" },
          letterSpacing: "normal",
          background: "none",
          transition: "all .25s ease-in-out",
          _hover: {
            color: "brand.gray.dark",
            background: "none",
            transition: "all .225s ease-in-out",
          },
        },
        ghostsmall: {
          paddingRight: { base: 8, md: 8, lg: 8 },
          paddingLeft: { base: 8, md: 8, lg: 8 },
          paddingTop: { base: 2, md: 3, lg: 3 },
          paddingBottom: { base: 2, md: 3, lg: 3 },
          border: "2px solid",
          display: "block",
          fontWeight: "bold",
          fontSize: { base: "xs", md: "xs" },
          letterSpacing: "normal",
          background: "none",
          transition: "all .25s ease-in-out",
          _hover: {
            color: "brand.violet.medviolet",
            background: "none",
            transition: "all .225s ease-in-out",
          },
        },
        round: {
          paddingRight: { base: 2, md: 3, lg: 3 },
          paddingLeft: { base: 2, md: 3, lg: 3 },
          paddingTop: { base: 2, md: 2, lg: 2 },
          paddingBottom: { base: 2, md: 3, lg: 3 },
          border: "2px solid",
          display: "flex",
          fontWeight: "bold",
          fontSize: { base: "xs", md: "xs" },
          letterSpacing: "normal",
          background: "none",
          transition: "all .25s ease-in-out",
          _hover: {
            color: "brand.violet.medviolet",
            background: "none",
            transition: "all .225s ease-in-out",
          },
        },
        small: {
          paddingRight: { base: 8, md: 8, lg: 8 },
          paddingLeft: { base: 8, md: 8, lg: 8 },
          paddingTop: { base: 2, md: 3, lg: 3 },
          paddingBottom: { base: 2, md: 3, lg: 3 },
          border: "2px solid",
          display: "block",
          fontWeight: "bold",
          fontSize: { base: "xs", md: "xs" },
          letterSpacing: "normal",
          background: "none",
          transition: "all .25s ease-in-out",
          _hover: {
            color: "brand.violet.medviolet",
            background: "none",
            transition: "all .225s ease-in-out",
          },
        },
      },
    },
    Container: {
      variants: {
        layoutContainer: {
          maxW: { base: "calc(100% - 1rem)", lg: "1440px" },
          boxSizing: "border-box",
          py: 0,
          px: 0,
        },
      },
    },
    Link: {
      baseStyle: {
        _hover: {
          textDecoration: "none",
        },
      },
      variants: {
        navlink: {
          textDecoration: "none",
          _hover: { textDecoration: "underline" },
        },
      },
    },
  },
  colors: {
    brand: {
      white: "#ffffff",
      violet: {
        ulight: "#B7ABE0",
        vlight: "#8072E0",
        light: "#6636F0",
        medviolet: "#3E0CA7",
        primary: "#26095F",
      },
      tan: {
        ulight: "#F3F1EB",
        vlight: "#E2DFD8",
        light: "#C8BCA3",
        primary: "#AE9E7B",
        dark: "#584E38",
      },
      gray: {
        ulight: "#DCE0EA",
        vlight: "#8F96A8",
        light: "#636A7D",
        primary: "#3B4357",
        dark: "#232835",
        vdark: "#17181C",
      },
    },
  },
})

export default theme
